<template>
	<div id="groupStudy">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>教务</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">小组管理</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="nav-tab-bar-box u-f-item u-f-jsb">
				<div class="nav-tab-bar u-f-item">
					<div class="font_14 nav-tab-item" @click="twacherTap(index)" :class="index == teacherIndex ? 'active' : ''" v-for="(item, index) in teacherMeun" :key="index">
						{{ item.name }}
					</div>
				</div>
			</div>
			<div class="content" style="height: 78.6vh;" v-if="teacherIndex == 0">
				<div class="title u-f-item u-f-jsb">
					<div>共{{ total }}条数据</div>
					<div class="u-f-item">
						<el-select clearable v-model="gradeId" placeholder="请选择年级" size="small" style="width: 120px;margin-right: 10px;" @change="gradecn">
							<el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
						<el-select clearable v-model="classId" placeholder="请选择班级" size="small" style="width: 120px;margin-right: 10px;" @change="classcn1">
							<el-option v-for="item in classList" :key="item.id" :label="item.class_name" :value="item.id"></el-option>
						</el-select>
						<el-select clearable v-model="subjectId" placeholder="请选择学科" size="small" style="width: 120px;margin-right: 10px;" @change="subjectcn">
							<el-option v-for="item in subjectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
						<el-select clearable v-model="type" placeholder="请选择类型" size="small" style="width: 120px;margin-right: 10px;" @change="typecn">
							<el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
						<el-select clearable v-model="name" placeholder="请选择小组" size="small" style="width: 120px;margin-right: 10px;" @change="groupcn">
							<el-option v-for="(item, index) in groupList" :key="index" :label="item.name" :value="item.name"></el-option>
						</el-select>
						<el-button size="small" type="primary" @click="export1">导出</el-button>
					</div>
				</div>
				<el-table :data="list" height="85%" ref="multipleTable" tooltip-effect="dark" style="width: 100%;border: 1px solid #EEEEEE;">
					<el-table-column type="index" label="序号" width="150" align="center"></el-table-column>
					<el-table-column prop="grade_name" label="年级" align="center"></el-table-column>
					<el-table-column prop="class_name" label="班级" align="center"></el-table-column>
					<el-table-column prop="name" label="小组名称" align="center"></el-table-column>
					<el-table-column prop="subject_name" label="科组" align="center"></el-table-column>
					<el-table-column prop="type" label="类型" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.type == 1">课堂表现</span>
							<span v-if="scope.row.type == 2">作业完成</span>
							<span v-if="scope.row.type == 3">晚修纪律</span>
						</template>
					</el-table-column>
					<el-table-column prop="score" label="分数" align="center"></el-table-column>
					<el-table-column prop="user_name" label="操作人" align="center"></el-table-column>
					<el-table-column prop="createtime" label="创建时间" align="center"></el-table-column>
					<el-table-column label="操作" width="120" align="center">
						<template slot-scope="scope">
							<el-button @click="del(scope.row.id)" type="text" size="small">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="u-f-right" style="padding: 10px 0;">
					<!-- <el-button type="danger" plain size="mini" @click="del">删除</el-button> -->
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page.sync="page"
						:page-size="limit"
						layout="total, prev, pager, next"
						:total="total"
					></el-pagination>
				</div>
			</div>

			<div class="content" style="height: 78.6vh;" v-if="teacherIndex == 1">
				<div class="title u-f-item u-f-jsb">
					<div>共{{ total }}条数据</div>
					<div class="u-f-item"><el-button size="small" @click="addUserShow = true">添加账号</el-button></div>
				</div>
				<el-table :data="listsOperator" height="85%" ref="multipleTable" tooltip-effect="dark" style="width: 100%;border: 1px solid #EEEEEE;">
					<el-table-column type="index" label="序号" width="150" align="center"></el-table-column>
					<el-table-column prop="grade_name" label="年级" align="center"></el-table-column>
					<el-table-column prop="class_name" label="班级" align="center"></el-table-column>
					<el-table-column prop="name" label="账号" align="center"></el-table-column>
					<el-table-column prop="createtime" label="创建时间" align="center"></el-table-column>
					<el-table-column label="操作" width="120" align="center">
						<template slot-scope="scope">
							<el-button @click="editscore(scope.row)" type="text" size="small">编辑</el-button>
							<span style="color: #E0E0E0;">|</span>
							<el-button @click="del(scope.row.id)" type="text" size="small">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="u-f-right" style="padding: 10px 0;">
					<!-- <el-button type="danger" plain size="mini" @click="del">删除</el-button> -->
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page.sync="page"
						:page-size="limit"
						layout="total, prev, pager, next"
						:total="total"
					></el-pagination>
				</div>
			</div>
			<el-dialog title="添加评分" :visible.sync="addShow" width="600px" :before-close="CloseHandle">
				<div class="edit-box" style="padding:0 0 20px 40px ;max-height: 500px;">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 80px;">
							<span>*</span>
							类型
						</div>
						<el-radio-group v-model="type" style="width: 75%;">
							<el-radio :label="1">课堂表现</el-radio>
							<el-radio :label="2">作业完成</el-radio>
							<el-radio :label="3">晚修纪律</el-radio>
						</el-radio-group>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 80px;">
							<span>*</span>
							年级
						</div>
						<el-select v-model="grade_id" placeholder="请选择" style="width: 75%;" @change="gradeChanage">
							<el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 80px;">
							<span>*</span>
							班级
						</div>
						<el-select v-model="class_id" placeholder="请选择" style="width: 75%;">
							<el-option v-for="item in classList" :key="item.id" :label="item.class_name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box" v-if="type != 3">
						<div class="input-lebal" style="width: 80px;">
							<span>*</span>
							学科
						</div>
						<el-select v-model="subject" placeholder="请选择" style="width: 75%;">
							<el-option v-for="item in subjectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 80px;">
							<span>*</span>
							时间
						</div>
						<el-date-picker v-model="time" style="width: 75%;" value-format="yyyy-MM-dd" format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
					</div>
					<div class="u-f-item input-box" v-for="(item, index) in groupList" :key="index">
						<div class="input-lebal" style="width: 80px;">
							<span>*</span>
							{{ item.name }}
						</div>
						<el-input-number v-model="item.score" :max="10"></el-input-number>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeadd" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submitAdd">确 定</el-button>
				</span>
			</el-dialog>

			<el-dialog title="编辑评分" :visible.sync="editShow" width="600px" :before-close="CloseHandle">
				<div class="edit-box" style="padding:0 0 20px 40px ;max-height: 500px;">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 80px;">
							<span>*</span>
							类型
						</div>
						<el-radio-group v-model="type" style="width: 75%;">
							<el-radio :label="1">课堂表现</el-radio>
							<el-radio :label="2">作业完成</el-radio>
							<el-radio :label="3">晚修纪律</el-radio>
						</el-radio-group>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 80px;">
							<span>*</span>
							年级
						</div>
						<el-select v-model="grade_id" placeholder="请选择" style="width: 75%;" @change="gradeChanage">
							<el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 80px;">
							<span>*</span>
							班级
						</div>
						<el-select v-model="class_id" placeholder="请选择" style="width: 75%;">
							<el-option v-for="item in classList" :key="item.id" :label="item.class_name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box" v-if="type != 3">
						<div class="input-lebal" style="width: 80px;">
							<span>*</span>
							学科
						</div>
						<el-select v-model="subject" placeholder="请选择" style="width: 75%;">
							<el-option v-for="item in subjectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box" v-if="type != 3">
						<div class="input-lebal" style="width: 80px;">
							<span>*</span>
							小组
						</div>
						<el-input v-model="name" placeholder="请输入内容" disabled style="width: 75%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 80px;">
							<span>*</span>
							时间
						</div>
						<el-date-picker v-model="time" style="width: 75%;" value-format="yyyy-MM-dd" format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 80px;">
							<span>*</span>
							分数
						</div>
						<el-input-number v-model="score" :max="10"></el-input-number>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeedit" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submitedit">确 定</el-button>
				</span>
			</el-dialog>

			<!-- 添加账号 -->
			<el-dialog title="添加账号" :visible.sync="addUserShow" width="600px" :before-close="CloseHandle">
				<div class="edit-box" style="padding:0 0 20px 40px ;max-height: 500px;">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 80px;">
							<span>*</span>
							年级
						</div>
						<el-select v-model="grade_id" placeholder="请选择" style="width: 75%;" @change="gradeChanage">
							<el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 80px;">
							<span>*</span>
							班级
						</div>
						<el-select v-model="class_id" placeholder="请选择" style="width: 75%;" @change="classChanage">
							<el-option v-for="item in classList" :key="item.id" :label="item.class_name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 80px;">
							<span>*</span>
							账号
						</div>
						<el-input v-model="account" placeholder="请输入账号" style="width: 75%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 80px;">
							<span>*</span>
							密码
						</div>
						<el-input v-model="password" placeholder="请输入密码" style="width: 75%;"></el-input>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeaddUser" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="addOperator">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			addUserShow: false,
			teacherMeun: [{ name: '小组评分', id: 1 }, { name: '小组配置', id: 2 }],
			teacherIndex: 0,
			addShow: false,
			editShow: false,
			type: 1,
			name: '',
			grade_id: '',
			grade_name: '',
			class_id: '',
			class_name: '',
			score: 0,
			time: '',
			subject: '',
			gradeList: [],
			classList: [],
			subjectList: [],
			groupList: [
				{ name: '第一小组', score: 0 },
				{ name: '第二小组', score: 0 },
				{ name: '第三小组', score: 0 },
				{ name: '第四小组', score: 0 },
				{ name: '第五小组', score: 0 },
				{ name: '第六小组', score: 0 },
				{ name: '第七小组', score: 0 },
				{ name: '第八小组', score: 0 },
				{ name: '第九小组', score: 0 }
			],
			list: [],
			total: 0,
			page: 1,
			limit: 10,
			eidtId: '',
			gradeId: '',
			classId: '',
			subjectId: '',
			account: '',
			password: '',
			listsOperator: [],
			typeList: [{ name: '课堂表现', id: 1 }, { name: '作业完成', id: 2 }, { name: '晚修纪律', id: 3 }],
			type: '',
			name: ''
		};
	},
	mounted() {
		this.getgradeList();
		this.getsubjectList();
		this.getList();
	},
	methods: {
		closeaddUser() {
			this.addUserShow = false;
			this.init();
		},
		getList() {
			let data = {
				page: this.page,
				limit: this.limit
			};
			if (this.gradeId) {
				data.grade_id = this.gradeId;
			}
			if (this.classId) {
				data.class_id = this.classId;
			}
			if (this.subjectId) {
				data.subject = this.subjectId;
			}
			if (this.type) {
				data.type = this.type;
			}
			if (this.name) {
				data.name = this.name;
			}
			this.$api.setting.teamList(data).then(res => {
				if (res.data.code == 1) {
					this.list = res.data.data;
					this.total = res.data.total;
				}
			});
		},
		twacherTap(index) {
			if (index == 0) {
				this.$router.push('/groupStudy');
			} else {
				this.$router.push('/groupIndex');
			}
		},
		typecn() {
			this.page = 1;
			this.getList();
		},
		groupcn() {
			this.page = 1;
			this.getList();
		},
		gradecn() {
			if (this.gradeId) {
				this.getClassList();
				this.classId = '';
				this.page = 1;
				this.getList();
			}
		},
		classcn1() {
			this.page = 1;
			this.getList();
		},
		subjectcn() {
			this.page = 1;
			this.getList();
		},
		gradeChanage() {
			if (this.grade_id) {
				for (let i in this.gradeList) {
					if (this.grade_id == this.gradeList[i].id) {
						this.grade_name = this.gradeList[i].name;
					}
				}
				this.getClassList();
				this.class_id = '';
			}
		},
		classChanage() {
			if (this.class_id) {
				for (let i in this.classList) {
					if (this.class_id == this.classList[i].id) {
						this.class_name = this.classList[i].name;
					}
				}
			}
		},
		CloseHandle(done) {
			done();
			this.init();
		},
		closeadd() {
			this.addShow = false;
			this.init();
		},
		submitedit() {
			let data = {
				id: this.eidtId,
				type: this.type,
				name: this.name,
				grade_id: this.grade_id,
				class_id: this.class_id,
				score: this.score,
				time: this.time
			};
			if (this.subject) {
				data.subject = this.subject;
			}
			this.$api.setting.teamedit(data).then(res => {
				if (res.data.code == 1) {
					this.$message.success('修改成功');
					this.editShow = false;
					this.init();
					this.page = 1;
					this.getList();
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		submitAdd() {
			let data = {
				type: this.type,
				list: this.groupList,
				grade_id: this.grade_id,
				class_id: this.class_id,
				time: this.time
			};
			if (this.subject) {
				data.subject = this.subject;
			}
			this.$api.setting.submitsScore(data).then(res => {
				if (res.data.code == 1) {
					this.$message.success('添加成功');
					this.addShow = false;
					this.init();
					this.page = 1;
					this.getList();
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 获取年级列表
		getgradeList() {
			this.$api.setting.getGradeList({}).then(res => {
				if (res.data.code == 1) {
					this.gradeList = res.data.data.rows;
				}
			});
		},
		// 获取班级列表
		getClassList() {
			this.$api.setting.getClassList({
				filter: JSON.stringify({ grade_id: this.grade_id || this.gradeId })
			}).then(res => {
				this.classList = res.data.rows;
			});
		},
		// 获取学科列表
		getsubjectList() {
			this.$api.setting.subjectList({}).then(res => {
				if (res.data.code == 1) {
					this.subjectList = res.data.data;
				}
			});
		},
		init() {
			this.type = 1;
			this.name = '';
			this.grade_id = '';
			this.class_id = '';
			this.score = 0;
			this.time = '';
			this.subject = '';
			this.classList = [];
			this.account = '';
			this.password = '';
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getList();
		},
		editscore(e) {
			this.eidtId = e.id;
			this.type = e.type;
			this.name = e.name;
			this.grade_id = e.grade_id;
			this.getClassList();
			this.class_id = e.class_id;
			this.score = e.score;
			this.time = e.time;
			this.subject = e.subject;
			this.eidtId = e.id;
			this.editShow = true;
		},
		del(id) {
			this.$confirm('确认要删除吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(() => {
				this.$api.setting.teamdel({ id: id }).then(res => {
					if (res.data.code == 1) {
						this.$message.success('删除成功');
						this.page = 1;
						this.getList();
					} else {
						this.$message.error(res.data.msg);
					}
				});
			}).catch(() => {});
		},
		closeedit() {
			this.editShow = false;
			this.init();
		},
		export1() {
			if (this.gradeId == '' || !this.gradeId) {
				return this.$message.error('请选择年级');
			}
			if (this.classId == '' || !this.classId) {
				return this.$message.error('请选择班级');
			}
			if (this.subjectId == '' || !this.subjectId) {
				return this.$message.error('请选择科组');
			}
			this.$api.setting.teamexports({
				grade_id: this.gradeId,
				class_id: this.classId,
				subject: this.subjectId
			}).then(res => {
				if (res.data.code == 1) {
					this.$message.success('导出成功');
					location.href = res.data.data;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 添加小组账号
		addOperator() {
			this.$api.setting.addOperator({
				grade_id: this.grade_id,
				class_id: this.class_id,
				grade_name: this.grade_name,
				class_name: this.class_name,
				account: this.account,
				password: this.password
			}).then(res => {
				if (res.data.code == 1) {
					this.$message.success('添加成功');
					this.getlistsOperator();
					this.init();
					this.addUserShow = false;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 获取小组账号列表
		getlistsOperator() {
			let data = {};
			this.$api.setting.getlistsOperator(data).then(res => {
				if (res.data.code == 1) {
					this.listsOperator = res.data;
				}
			});
		}
	}
};
</script>

<style lang="scss">
#groupStudy {
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 0.52vw;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.53125vw;
			}
		}
	}
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}

	.content {
		background-color: #ffffff;
		height: 79vh;
		margin-top: 20px;
		padding: 0 30px;
		.title {
			padding: 15px 0;
			.btn {
				background-color: #f3f3f3;
				font-size: 12px;
				padding: 5px 10px;
				color: #555555;
			}
		}
	}

	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
	.el-table thead {
		background: #fafafa !important;
	}
	.el-table th {
		padding: 15px 5px !important;
		background: #fafafa !important;
	}
	.el-table::before {
		left: 0;
		bottom: 0;
		width: 100%;
		height: 0;
	}
	.pad {
		background-color: #e4f1ff;
		color: #2d8cf0;
		padding: 6px 20px;
		display: inline-block;
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		.edit-box {
			padding: 0 20px 0 20px;
			max-height: 60vh;
			overflow-y: auto;
			.input-box {
				margin-top: 35px;
				.input-lebal {
					width: 25%;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.dialog-title {
		padding: 10px 0 5px 0;
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
.selectShow {
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		.user-list-box {
			.user-list-head {
				padding: 10px 20px;
				border-bottom: 1px solid #eeeeee;
				.user-type-list {
					.user-type-item {
						color: #8d9da6;
						cursor: pointer;
					}
					.active {
						color: #007aff;
						position: relative;
					}
					.active::after {
						position: absolute;
						width: 25px;
						height: 2px;
						background-color: #007aff;
						content: '';
						bottom: -5px;
						left: 0;
					}
					span {
						color: #cccccc;
						margin: 0 8px;
					}
				}
			}
			.user-list-content {
				height: 40vh;
				.left {
					height: 98%;
					overflow-y: auto;
					border-right: 1px solid #eeeeee;
					padding-top: 5px;
					.left-item {
						padding: 0 20px;
						height: 40px;
						line-height: 40px;
						color: #a4a4a4;
						cursor: pointer;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.active {
						background-color: #e4f1ff;
						color: #2d8cf0;
						position: relative;
					}
					.active:after {
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						width: 2px;
						content: '';
						background-color: #2d8cf0;
					}
				}
				.right {
					height: 37vh;
					padding: 10px 25px;
					overflow-y: auto;
					.list {
						display: flex;
						flex-wrap: wrap;
						.item {
							cursor: pointer;
							padding: 5px 15px;
							border-radius: 3px;
							background-color: #f3f3f3;
							margin-right: 10px;
							margin-bottom: 10px;
						}
						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
						}
					}
				}
			}
		}
	}
	.dialog-title {
		padding: 10px 0 5px 0;
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
